















































































.framePage .framePage-body {
  background: transparent;
}
.shell {
    background: #fff;
    padding-bottom: 30px;
}
.v_1 {
  width: 100%;
}
.v_2 {
  text-align: center;
  font-size: 32px;
  margin-top: 16px;
}
.v_5 {
  text-align: center;
  font-size: 32px;
  margin-top: 10px;
}
.v_3 {
  width: 60%;
  margin: auto;
  text-indent: 2em;
  margin-top: 20px;
  color: #666666;
}
.v_4 {
  width: 90%;
  margin: auto;
  display: flex;
  margin-top: 20px;
  div {
    width: 33%;
    border: 4px solid #fff;
    border-radius: 6px;
    transition: all 0.3s linear;
    position: relative;
    img {
      width: 100%;
    }
    p {
      position: absolute;
      left: 0;
      bottom: 0;
      text-align: center;
      width: 100%;
      line-height: 40px;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      background: rgba(0, 0, 0, 0.4);
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      // transition: all 1s;
      transition: all 0.5s ease 0.1s;
      z-index: 2;
    }
  }
}
